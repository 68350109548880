const FormatDate = ({ date, widthTime = false }) => {
  if (date) {
    let dateToFormat = new Date(date);

    let parts = date.split("-");
    if (parts[2].length === 4)
      dateToFormat = new Date(parts[2] + "-" + parts[1] + "-" + parts[0]);

    let dd = dateToFormat.getDate();
    let mm = dateToFormat.getMonth() + 1;
    let yyyy = dateToFormat.getFullYear();

    let hh = dateToFormat.getHours();
    let mi = dateToFormat.getMinutes();

    if (mi < 10) {
      mi = "0" + mi;
    }

    if (dd < 10) {
      dd = "0" + dd;
    }
    if (mm < 10) {
      mm = "0" + mm;
    }

    if (widthTime) {
      return dd + "/" + mm + "/" + yyyy + ", " + hh + ":" + mi;
    } else {
      return dd + "/" + mm + "/" + yyyy;
    }
  } else {
    return "";
  }
};

export default FormatDate;
