import "./App.css";
import Header from "./components/Header";
import Home from "./views/Home";
import Login from "./views/Login";
import ResetPw from "./views/Login/LostPassword";
import { useSelector } from "react-redux";

import { BrowserRouter as Router, Route } from "react-router-dom";

const App = () => {
  const isLoggedIn = useSelector((state) => state.isLogged.isLoggedIn);

  return isLoggedIn ? (
    <>
      <Router>
        <Header />
        <Route component={Home} path="/" exact />
      </Router>
    </>
  ) : (
    <Router>
      <Route component={Login} path="/" exact />
      <Route component={ResetPw} path="/reset-password" exact />
    </Router>
    
  );
};

export default App;
