import "./main.css";
import { useState } from "react";
// redux
import { loggIn } from "../../providers/actions";
import { useDispatch, useSelector } from "react-redux";

import { TextField, Paper, Button, FormControl } from "@material-ui/core";
import purple from "@material-ui/core/colors/purple";

import Loading from "../../components/Loading";
const Login = ({ history }) => {
  const isLoggedIn = useSelector((state) => state.isLogged.isLoggedIn);

  if (isLoggedIn) {
    setTimeout(() => {
      window.location.href = "/";
    }, 1000);
  }

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    const email = event.target.email.value;
    const pass = event.target.password.value;

    if (!email && !pass) {
      alert("You must enter your email and your password");
      return false;
    }

    setIsLoading(true);
    var authorization = "";
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      authorization = user.token;
    }
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/login_check`,
        {
          method: "POST",
          headers: {
            Authorization: "bearer " + authorization,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            username: email,
            password: pass,
          }),
        }
      );

      const res = await response.json();

      // return false;
      if (res.token) {
        dispatch(loggIn(res));
        setTimeout(() => {
          history.push("/");
        }, 2000);
      } else if (response.code === 401) {
        //console.log("error", response);
        alert("Invalid Email or Password");
      }

      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      alert("Email or Password are invalid");
    }

    setIsLoading(false);
  };

  return (
    <div className="donate__loginPage__container">
      <Loading open={isLoading} />
      <div className="donate__login__paper__container">
        <div align="center" className="image__conatiner">
          <img src="logoAgil.png" alt="Agil" />
        </div>
        <Paper className="donate__login__paper">
          <h1 align="center" style={{ margin: "0.7em 0" }}>
            S'identifier
          </h1>
          <form
            noValidate
            autoComplete="on"
            onSubmit={handleLoginSubmit}
            className="donate__login__form"
          >
            <TextField
              name="email"
              className="donate__login__el"
              type="email"
              label="Email"
            />
            <TextField
              name="password"
              className="donate__login__el"
              type="password"
              label="Mot de passe"
            />
            <FormControl>
              <Button
                variant="contained"
                type="submit"
                style={{ backgroundColor: "#FFD733" }}
              >
                Se connecter
              </Button>
            </FormControl>
          </form>
          <h4 style={{cursor: "pointer"}} onClick={(e) => { e.preventDefault(); history.push("/reset-password"); }}> Lost your password? </h4>
        </Paper>
      </div>
    </div>
  );
};

export default Login;
