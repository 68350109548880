import "bootstrap/dist/css/bootstrap.min.css";

import { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import "./index.css";
import FormatDate from "../../components/FormatDate";

import { useDispatch } from "react-redux";

import { loggOut } from "../../providers/actions";
const Home = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const [shouldRefresh, SetShouldRefresh] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL_RESET}/tasks`, {
        method: "GET",
        headers: {
          Authorization:
            "bearer " + JSON.parse(localStorage.getItem("user")).token,
          "Content-Type": "application/json",
        },
      });

      const res = await response.json();
      setData(res.data);
      console.log(res.data);
      const id = setTimeout(() => SetShouldRefresh(true), 1000 * 60 * 60);
      return () => clearTimeout(id);
    };
    getData();
  }, [shouldRefresh]);

  const handleLogout = () => {
    dispatch(loggOut());
  };
  return (
    <>
      <div
        style={{ background: "#fed102", color: "black", padding: "15px 5px" }}
      >
        <Row style={{ alignItems: "center" }}>
          <Col md={1} align="center">
            <img alt="Agil logo" src="/logo-agil.png" className="img-logo" />
          </Col>
          <Col md={10} align="center">
            <h2 className="page__title">
              PLANNING DES CONTROLES QUALITES DES INSTALLATIONS FIXES, MOBILES
              ET EQUIPEMENTS
            </h2>
          </Col>
          <Col md={1} align="center">
            <button
              style={{ border: "none", background: "transparent" }}
              onClick={handleLogout}
            >
              <img
                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAYAAACqaXHeAAACoklEQVR4Xu2az0sVURTHP5btqk0aohDSoloW9kPLTf4PYi4KpH20bFP+KAqCwH/AjSBFtNeCbJu6KAT/AcGFZH9BUXFgXjye8+beeXPP8x7nznbunHO+n3vOuTPD6aHmV0/N9ZMApAyoOYFUAjVPgNQEy5TAVeAJcBfohyjh/QTWgZfAd5/s9gVwH1gCTvkYjWDNb+AhsOyKxQeA7PymIfENzQLhJvCtCIIPgHfAlItkpPffu2L3AbAPnI9UoCusg6xftV3nA+BPTsN7A3x1ee/y/TvA4xyfhRp9APzNMToJfOiyQJe7e8DbBOAwgZQBVU+BVAJKPeACsOsq7BL3TfWAcWAVkNNkroTIoqVmAMhxtQacztTMB4JgAsDtTPyZlq0MASF6ACeBHeBymzyuCiF6AKL7EvAFGFSAoAYgUI/6b0YLghkAWplgCoAGBHMAQkMwCSAkBDUA3fjsHQZGCrqtzxGpBiDvYyj0yeBjzwXh2AMQSEUQagFAPpwEQt517AEUiRcgagDkt7j2dRG4UeDEJV4VgLb4K9n3wUAbR7PAgkcQahng4bvjJaHEm8yAkOLNAXCJfwY8L5lXZkpAQ7xqBoT8La4l3gQA1y+xp8CLkmnfvFytBEJmwC3gE3C2RWhV8SYyoKF5FPjYBCGEeFMAJNixDMLrimlvrgSaAx4C9irUfOujJnpAQL2HTCUAaUAiTYh0b0RmEdjQLOgObMvJ8qjlOXmHOVFky2dISkbNznUQUAyP/HCN+PkAkGFDmQqzeMnfrOmqGSCjsltArzECv4DrwHZVAPL8g2xY2goEET8DrLg2zacEGjauZePyE0Cfy/AR3JeGJ/3qM/DKtfON+MoAOAJN+i4TAH3GcXtIGRD3/uhHlzJAn3HcHmqfAf8ABEGmQRL0m8AAAAAASUVORK5CYII="
                alt="déconnexion"
                title="Déconnexion"
                style={{ width: "40px" }}
              />
            </button>
          </Col>
        </Row>
      </div>
      <h2 style={{ fontFamily: "Times New Roman", color: "#fed102" }}>
        Agil air{" "}
        <span style={{ textTransform: "capitalize" }}>
          {Object.keys(data).map((category, i) =>
            i === 0 ? data[category][0].event.location.name : ""
          )}
          {/* monastir */}
        </span>
      </h2>
      <div className="container__container">
        <Container>
          {Object.keys(data).map((category, i) => (
            <Row>
              <Col
                md={1}
                style={{ margin: "auto", color: category.color }}
                className="col-yellow"
              >
                {category}
              </Col>
              <Col md={11}>
                <Table>
                  <thead>
                    <tr style={{ backgroundColor: "#282e3a" }}>
                      <th width="10%" className="col-yellow">
                        Imprimes
                      </th>
                      <th width="20%" className="col-yellow">
                        Désignation
                      </th>
                      <th width="10%" className="col-yellow">
                        Établissement
                      </th>
                      <th width="10%" className="col-yellow">
                        Fréquence{" "}
                      </th>
                      <th width="10%" className="col-yellow">
                        Résponsables
                      </th>
                      <th width="10%" className="col-yellow">
                        Concernés
                      </th>
                      <th width="10%" className="col-yellow">
                        Controlé par
                      </th>
                      <th width="10%" className="col-yellow">
                        Date
                      </th>
                      <th width="10%" className="col-yellow">
                        Status
                      </th>
                    </tr>
                  </thead>
                  <tbody className="tab-border">
                    {data[category].map((hist, i) => (
                      <tr
                        style={{
                          backgroundColor: hist.event.frequence.color,
                        }}
                      >
                        <td
                          style={{ textAlign: "left", color: "black" }}
                          width="10%"
                          key={i + hist.event.imprime.name}
                        >
                          {hist.event.imprime.name}
                        </td>
                        <td
                          style={{ textAlign: "left", color: "black" }}
                          width="20%"
                          key={i + hist.event.name}
                        >
                          {hist.event.name}
                        </td>
                        <td
                          style={{ textAlign: "left", color: "black" }}
                          width="10%"
                          key={i + hist.event.etablissement}
                        >
                          {hist.event.etablissement}
                        </td>
                        <td
                          width="10%"
                          key={i + hist.event.frequence.name}
                          style={{ color: "black" }}
                        >
                          {hist.event.frequence.name}
                        </td>
                        <td
                          width="10%"
                          key={i + hist.event.responsible.name}
                          style={{ color: "black" }}
                        >
                          {hist.event.responsible.name}
                        </td>
                        <td
                          width="10%"
                          key={i + hist.event.concerne.name}
                          style={{ color: "black" }}
                        >
                          {hist.event.concerne.name}{" "}
                        </td>
                        <td
                          width="10%"
                          key={i + hist.event.controle.name}
                          style={{ color: "black" }}
                        >
                          {hist.event.controle.name}
                        </td>
                        <td
                          width="10%"
                          key={i + hist.event.date}
                          style={{ color: "black" }}
                        >
                          {/* {hist.createdAt.date} */}
                          <FormatDate date={hist.createdAt.date} />
                        </td>
                        <td width="10%" key={i + hist.status}>
                          {hist.status ? (
                            <Badge variant="success">éxecuté</Badge>
                          ) : (
                            <Badge variant="danger">à éxecuter</Badge>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          ))}
        </Container>
      </div>
    </>
  );
};

export default Home;
